import React from "react";
import { SocialIcon } from "react-social-icons";

function Contact() {
  return (
    <div className="h-screen relative flex flex-col justify-evenly items-center mx-auto">
      <h3 className="absolute top-24 uppercase tracking-[20px] text-gray-500 text-2xl">
        contact
      </h3>

      <div className="flex flex-col gap-5 max-w-[260px] sm:max-w-full">
        <p className="text-xl text-center text-gray-300">
          Do you have a project idea and do you need a website or a bot?
        </p>
        <p className="text-xl text-center text-gray-100">
          Contact with us on{" "}
          <span className="text-[#7289da] font-bold underline underline-offset-4 drop-shadow-[0px_0px_11px_#7289da]">
            Discord
          </span>{" "}
          or{" "}
          <span className="text-[#229ED9] font-bold underline underline-offset-4 drop-shadow-[0px_0px_11px_#229ED9]">
            Telegram
          </span>{" "}
          and lets build your project!
        </p>

        <div className="flex flex-col space-y-4 sm:flex-row items-center justify-center sm:space-x-4 sm:space-y-0 mt-8">
          <a
            href="https://discord.gg/zypnet"
            className="flex flex-row items-center space-x-4 px-6 py-2 rounded-lg bg-[#7289da] ease-in-out duration-300 hover:shadow-[0px_0px_11px_3px_#7289da]"
          >
            <SocialIcon
              url="https://discord.gg/zypnet"
              bgColor="#5c6fb5"
              fgColor="#fff"
            />
            <span className="font-bold text-xl">Discord</span>
          </a>

          <a
            href="https://t.me/zypnet"
            className="flex flex-row items-center space-x-4 px-6 py-2 rounded-lg bg-[#208cbe] ease-in-out duration-300 hover:shadow-[0px_0px_11px_3px_#208cbe]"
          >
            <SocialIcon
              url="https://t.me/zypnet"
              bgColor="#2AABEE"
              fgColor="#fff"
            />
            <span className="font-bold text-xl">Telegram</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
