import React from "react";

function Skills() {
  /*const skillNames = [
    "html5, css3, sass, tailwindcss, bootstrap, react, javascript, python, selenium, discordjs, nodejs, mongodb, git, npm, github, c, photoshop, illustrator",
  ];*/
  return (
    <div className="flex flex-col relative h-screen text-center md:text-left xl:flex-row max-w-7xl px-10 justify-evenly mx-auto items-center">
      <h3 className="absolute top-24 uppercase tracking-[20px] text-gray-500 text-2xl">
        skills
      </h3>

      <div className="flex flex-col md:flex-row gap-5 md:gap-20 mt-12">
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4 cursor-pointer">
          <i className="devicon-html5-plain text-[2.5rem] md:text-[4rem] text-[#e34c26]"></i>
          <i className="devicon-css3-plain text-[2.5rem] md:text-[4rem] text-[#264de4]"></i>
          <i className="devicon-sass-plain text-[2.5rem] md:text-[4rem] text-[#cd6799]"></i>
          <i className="devicon-tailwindcss-plain text-[2.5rem] md:text-[4rem] text-blue-400"></i>
          <i className="devicon-bootstrap-plain text-[2.5rem] md:text-[4rem] text-[#563d7c]"></i>
          <i className="devicon-react-plain text-[2.5rem] md:text-[4rem] text-blue-300"></i>
          <i className="devicon-javascript-plain text-[2.5rem] md:text-[4rem] text-yellow-400"></i>
          <i className="devicon-python-plain text-[2.5rem] md:text-[4rem] text-[#4B8BBE]"></i>
          <i className="devicon-selenium-plain text-[2.5rem] md:text-[4rem] text-green-400"></i>
          <i className="devicon-discordjs-plain text-[2.5rem] md:text-[4rem] text-yellow-400"></i>
          <i className="devicon-nodejs-plain text-[2.5rem] md:text-[4rem] text-yellow-400"></i>
          <i className="devicon-mongodb-plain text-[2.5rem] md:text-[4rem] text-green-400"></i>
          <i className="devicon-git-plain text-[2.5rem] md:text-[4rem] text-orange-600"></i>
          <i className="devicon-npm-original-wordmark colored text-[2.5rem] md:text-[4rem]"></i>
          <i className="devicon-github-plain text-[2.5rem] md:text-[4rem]"></i>
          <i className="devicon-c-plain text-[2.5rem] md:text-[4rem] text-blue-900"></i>
          <i className="devicon-photoshop-plain colored text-[2.5rem] md:text-[4rem]"></i>
          <i className="devicon-illustrator-plain text-[2.5rem] md:text-[4rem] text-[#f8a829]"></i>
        </div>
        <div>
          <p className="font-bold text-xl">We have knowledge about: </p>
          <ul className="list-disc text-gray-300">
            <li>Developing Websites</li>
            <li>Developing Discord Bots</li>
            <li>Developing Databases</li>
            <li>Developing C, Python Tools</li>
            <li>Designing Threads</li>
            <li>Designing Logos</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Skills;
