import React from "react";

function Projects() {
  const projects = [
    {
      _id: 1,
      title: "OTP Bot Website",
      description: "Made for a client. A beautiful website to sell otp bot.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675592219/image_m9jsze.png",
    },
    {
      _id: 2,
      title: "Discord Boost Tool",
      description:
        "Made with Python request library. It can join servers and boost the server. Made for my own business but sold a few copy.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675590196/image_2_qdm2d7.png",
    },
    {
      _id: 3,
      title: "Discord Ticket Bot",
      description:
        "This is just a basic ticket bot with discord.js buttons. Used discord.js and mongodb.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675590456/Screenshot_1_jeqf3e.png",
    },
    {
      _id: 4,
      title: "CoinSwapper Website",
      description:
        "Made for a client. A website that you can convert your coins to other coins.",
      image:
        "http://res.cloudinary.com/dcm3qaubk/image/upload/v1694363649/images/untitled.png",
        href: "https://coinswapper.app/",
    },
    {
      _id: 5,
      title: "Discord Sellix Webhook Bot",
      description:
        "When someone buys a product or create an order on sellix, it will send a message to specific channel. Made with discord.js and sellix api.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675590633/Untitled_74_ok5j2z.png",
    },
    {
      _id: 6,
      title: "E-Commerce Website",
      description:
        "A custom website like atshop. Made for myself long time ago.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675591969/image_dtiewa.png",
    },
    {
      _id: 7,
      title: "Snowx Store",
      description:
        "Made for a client. This website is integrated with sellix api. It can show all products, categories, feedbacks, and more.",
      image:
        "https://res.cloudinary.com/dcm3qaubk/image/upload/v1675592537/image_iewek5.png",
    },
    {
      _id: 8,
      title: "Sms Sender Website",
      description:
        "Sms sender with dashboard. Made with react and node.js.",
      image:
        "https://cdn.discordapp.com/attachments/1094363685922803812/1135739885249110168/imagex.png",
    },
  ];

  return (
    <div className="h-screen relative flex overflow-hidden flex-col text-left md:flex-row max-w-full justify-evenly mx-auto items-center z-0">
      <h3 className="absolute top-24 uppercase tracking-[20px] text-gray-500 text-2xl">
        projects
      </h3>

      <div className="relative w-full flex overflow-x-scroll overflow-y-hidden snap-x snap-mandatory z-20 scrollbar-thin scrollbar-thumb-purple-900 scrollbar-transparent">
        {projects.map((project) => (
          <a href={project.href} className="w-screen flex-shrink-0 snap-center flex flex-col space-y-5 items-center justify-center p-20 md:p-44 h-screen" key={project._id}>
            <img
              className="w-[300px] object-cover hover:w-[450px] duration-500"
              src={project.image}
              alt={project.title + " showcase"}
            />
            <div
              className="space-y-10 px-0 md:px-10 max-w-6xl text-center"
            >
              <h4 className="font-bold text-xl">{project.title}</h4>
              <p className="text-gray-400 my-2">{project.description}</p>
            </div>
          </a>
        ))}
      </div>

      <div className="w-full absolute top-[30%] bg-[#710193]/10 left-0 h-[500px] -skew-y-12"></div>
    </div>
  );
}

export default Projects;
