import React from "react";

function About() {
  return (
    <div className="flex flex-col relative h-screen text-center md:text-left xl:flex-row max-w-7xl px-10 justify-evenly mx-auto items-center">
      <h3 className="absolute top-24 uppercase tracking-[20px] text-gray-500 text-2xl">
        about us
      </h3>

      <div className="flex flex-col md:flex-row items-center text-center mt-4">
        <img
          src="https://cdn.discordapp.com/attachments/1199710787091058830/1273338904229187657/tvhead.png"
          alt="about-img"
        />
        <div className="text-2xl">
          <p>
            Hello! We are{" "}
            <span className="tracking-widest text-[#8D2FCB] font-bold">
              ZYPNET
            </span>
            .
          </p>

          <p className="mt-4 text-gray-400 text-lg md:text-2xl">
            We are developers & designers who love to create beautiful things.
            We are creating amazing websites, discord bots, logo designs, thread
            designs, and much more for 3+ years.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
