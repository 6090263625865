import React from "react";
import * as smoothscroll from "smoothscroll-polyfill";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";

function Hero() {
  const [text] = useTypewriter({
    words: [
      "Hello! We are ZYPNET.",
      "Web Developer",
      "Discord Bot Developer",
      "Thread & Logo Designer",
    ],
    loop: true,
    delaySpeed: 2000,
  });
  smoothscroll.polyfill();
  return (
    <motion.div
      initial={{
        z: -100,
        opacity: 0,
      }}
      transition={{ duration: 1.4 }}
      animate={{
        z: 0,
        opacity: 1,
      }}
      className="h-screen flex flex-col items-center justify-center text-center overflow-hidden"
    >
      <img
        className="rounded-full w-28 m-4"
        src="https://static.cracked.io/uploads/avatars/avatar_1877631.gif?dateline=1622053508"
        alt="Logo"
      />

      <div className="m-4">
        <h2 className="text-3xl tracking-widest font-bold">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 drop-shadow-[0px_0px_11px_#8b5cf6] text-4xl">ZYPNET</span> <br /> Development & Design Service
        </h2>
        <h3 className="text-lg font-medium text-gray-400 tracking-wider mt-4">
          {text}
          <Cursor cursorColor="#fff" />
        </h3>
      </div>

      <div className="flex flex-row flex-wrap items-center justify-center m-8">
        <button
          onClick={() => {
            let hero = document.getElementById("about-us");
            hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          className="font-medium text-lg uppercase mx-4 underline underline-offset-8 hover:text-gray-400 my-2"
        >
          about us
        </button>
        <button
          onClick={() => {
            let hero = document.getElementById("skills");
            hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          className="font-medium text-lg uppercase mx-4 underline underline-offset-8 hover:text-gray-400 my-2"
        >
          skills
        </button>
        <button
          onClick={() => {
            let hero = document.getElementById("projects");
            hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          className="font-medium text-lg uppercase mx-4 underline underline-offset-8 hover:text-gray-400 my-2"
        >
          projects
        </button>
        <button
          onClick={() => {
            let hero = document.getElementById("contact");
            hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          className="font-medium text-lg uppercase mx-4 underline underline-offset-8 hover:text-gray-400 my-2"
        >
          contact
        </button>
        
      </div>
    </motion.div>
  );
}

export default Hero;
