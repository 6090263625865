import React from "react";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";

function Header() {
  const handleReload = () =>{
    window.location.href = "http://localhost:3000";
  }
  return (
    <header className="sticky top-0 p-5 flex items-start justify-between max-w-7xl mx-auto z-20 xl:items-center">
        <motion.div
          initial={{
            x: -500,
            opacity: 0,
            scale: 0.5,
          }}
          transition={{
            duration: 1.4,
          }}
          animate={{
            x: 0,
            opacity: 1,
            scale: 1,
          }}
        >
          <h1 className="text-center text-3xl font-bold tracking-widest align-middle cursor-pointer" onClick={handleReload}>
            ZYPNET
          </h1>
        </motion.div>

        <motion.div
          initial={{
            x: 500,
            opacity: 0,
            scale: 0.5,
          }}
          transition={{
            duration: 1.4,
          }}
          animate={{
            x: 0,
            opacity: 1,
            scale: 1,
          }}
          className="flex flex-row items-center cursor-pointer"
        >
          <SocialIcon
            url="https://discord.gg/zypnet"
            bgColor="#fff"
            fgColor="#161515"
          />
          <a href="https://discord.gg/zypnet" className="hidden md:inline-flex ml-4 text-lg capitalize tracking-wider">
            get in touch
          </a>
        </motion.div>
    </header>
  );
}

export default Header;
